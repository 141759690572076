exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-bill-analizer-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/projects/billAnalizer/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-bill-analizer-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-caikei-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/projects/caikei/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-caikei-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-hotel-booking-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/projects/hotelBooking/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-hotel-booking-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-portfolio-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/projects/portfolio/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-portfolio-index-mdx" */)
}

